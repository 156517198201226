import React from "react";
import "./MultiGrainWholeMeal.css";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import { useRef } from "react";

function MultiGrainWholeMeal() {
  const con = useRef();

  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
  return (
    <div className="wholeMeal">
      <div className="wholeMeal__headerContent">
        <div className="headerMobileImageContainer">
          <img
            src={require("../../assets/WholeMeal1.png")}
            alt=""
            className="wholeMeal__headerImage wholeMeal__headerImageMobile"
            data-aos="fade-left"
          />
        </div>
        <div className="wholeMeal__headerContentCircle"></div>
        <div className="wholeMeal__headerContentLeft">
          <h1 className="wholeMeal__headerContentName" data-aos="fade-up">
            Multi-Grain Whole Meal
          </h1>
          <p className="wholeMeal__headerContentText" data-aos="fade-up">
            Multigrain Whole Meal is a low glycemic swallow alternative made with whole grains, millet, guinea corn and unripe plantain. It is a rich source of fibre, antioxidants, prebiotics, and nutrients.
          </p>
          <a href="#wholeMealContent" style={{ textDecoration: "none", cursor: "pointer" }}>
            <div className="wholeMeal__headerButton" data-aos="fade-up">
              <div className="wholeMeal__headerIcon"></div>
              <span className="wholeMeal__headerButtonText">Learn more</span>
            </div>
          </a>
        </div>
        <img
          src={require("../../assets/WholeMeal1.png")}
          alt=""
          className="wholeMeal__headerImage"
          data-aos="fade-left"
        />
      </div>
      <div className="wholeMeal__content" id="wholeMealContent" ref={con}>
        <div className="wholeMeal__contentImage">
          <img
            src={require("../../assets/WholeMeal2.png")}
            alt=""
            className="wholeMeal__contentImage"
          />
        </div>
        <div className="wholeMeal__contentContent">
          <h2 className="wholeMeal__contentContentTitle" data-aos="fade-up">
            About Multi-Grain Whole Meal
          </h2>
          <p className="wholeMeal__contentContentText" data-aos="fade-up">
            {`Multigrain Whole Meal is a low glycemic swallow alternative made with whole grains, millet, guinea corn and unripe plantain. It is a rich source of fibre, antioxidants, pre-biotics, and nutrients.`}
          </p>
          <h2 className="wholeMeal__contentContentTitle" data-aos="fade-up">
            Key Benefits
          </h2>
          <p className="wholeMeal__contentContentText" data-aos="fade-up">
            100 whole grains <br />
            Rich source of pre-biotic fiber <br />
            Rich source of nutrients especially Magnesium, Iron, Calcium etc{" "}
            <br />
            Gluten-free 
          </p>
          <h2 className="wholeMeal__contentContentTitle" data-aos="fade-up">
            Directions for use
          </h2>
          <p className="wholeMeal__contentContentText" data-aos="fade-up">
            Use as directed on the label.
          </p>

          <h2 className="wholeMeal__contentContentTitle" data-aos="fade-up">
            Key Ingredients
          </h2>
          <p className="wholeMeal__contentContentText" data-aos="fade-up">
            Millet, sorghum and unripe plantain
          </p>
        </div>
      </div>
    </div>
  );
}

export default MultiGrainWholeMeal;
