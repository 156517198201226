import React, { useEffect } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/CharisLogo.svg";
import { FiSearch } from "react-icons/fi";
import { BiMenu } from "react-icons/bi";
import { useState } from "react";

function Header() {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  return (
    <div className="header">
      <div className="header__left">
        <img
          src={Logo}
          alt=""
          className="header__logo"
          onClick={() => navigate("/")}
        />
        <div
        // className="header__searchBar"
        >
          {/* <FiSearch />
          <input type="text" className="header__searhInput" /> */}
        </div>
      </div>
      <div className="header__right">
        <div
          className="header__button header__nav"
          onClick={() => navigate("/")}
        >
          Home
        </div>
        <a
          href="#products"
          style={{ textDecoration: "none" }}
          onClick={() => navigate("/")}
        >
          <div className="header__button header__nav">Our Products</div>
        </a>
        <div
          className="header__button header__nav"
          onClick={() => navigate("/about")}
        >
          About Us
        </div>
        <div
          className="header__button header__nav"
          onClick={() => navigate("/where")}
        >
          Where To Buy
        </div>
        <div className="header__button" onClick={() => navigate("/contact")}>
          Get In Touch
        </div>
      </div>
      <div className="header__menuIcon">
        <BiMenu onClick={() => setVisible(!visible)} />
        {visible && (
          <div className="header__menuItems">
            <span
              className="header__menuItem"
              onClick={() => {
                setVisible(false);
                navigate("/");
              }}
            >
              Home
            </span>
            <a
              href="#products"
              className="header__menuItem"
              style={{ textDecoration: "none", color: "black" }}
              onClick={() => {
                setVisible(false);
                navigate("/");
              }}
            >
              Our Products
            </a>
            <span
              className="header__menuItem"
              onClick={() => {
                setVisible(false);
                navigate("/about");
              }}
            >
              About Us
            </span>
            <span
              className="header__menuItem"
              onClick={() => {
                setVisible(false);
                navigate("/where");
              }}
            >
              Where To Buy
            </span>
            <span
              className="header__menuItem"
              onClick={() => {
                setVisible(false);
                navigate("/contact");
              }}
            >
              Get in Touch
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
