import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/CharisLogo.svg";
import { BsInstagram, BsDribbble, BsFacebook } from "react-icons/bs";
import { FiTwitter } from "react-icons/fi";
import {RiFacebookBoxLine} from "react-icons/ri";

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="footer__top">
        <div className="footer__column footer__columnLeft">
          <img
            src={logo}
            alt=""
            className="footer__logo"
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
          />
          <p className="footer__name">Charis Foods</p>
          <p className="footer__leftBottom">Functional and wholesome foods.</p>
        </div>
        <div className="footer__column">
          <span className="footer__columnTitle">Discover</span>
          <span
            className="footerColumnRow"
            onClick={() => navigate("/product/syrup")}
          >
            Premium Date Fruit Syrup
          </span>
          <span
            className="footerColumnRow"
            onClick={() => navigate("/product/grain")}
          >
            Multi Grain Cereal
          </span>
          <span
            className="footerColumnRow"
            onClick={() => navigate("/product/meal")}
          >
            Multi Grain Whole Meal
          </span>
          <span
            className="footerColumnRow"
            onClick={() => navigate("/product/oats")}
          >
            Oats Cereal Mix
          </span>
          <span
            className="footerColumnRow"
            onClick={() => navigate("/product/date")}
          >
            Dates Seed Tea
          </span>
        </div>
        <div className="footer__column footer__mobile">
          <span className="footer__columnTitle">Company</span>
          <span className="footerColumnRow" onClick={() => navigate("/about")}>
            About US
          </span>
          <a
            href="http://blog.charisfood.com"
            target="blank"
            style={{ textDecoration: "none", color: "black" }}
            className="footerColumnRow"
          >
            Blog
          </a>
          <span className="footerColumnRow" onClick={() => navigate("/where")}>
            Distributors
          </span>
          <span
            className="footerColumnRow"
            onClick={() => navigate("/contact")}
          >
            Contact Us
          </span>
        </div>
        <div className="footer__column">
          <span className="footer__columnTitle footer__mobile">Social</span>
          <div className="footer__socials">
            <a href="https://www.instagram.com/charisfoodng/" target="blank" className="footer__social">
              <BsInstagram />
            </a>
            <a href="https://www.facebook.com/charisfoodng/" target="blank" className="footer__social">
              <BsFacebook />
            </a>
            <a href="https://www.twitter.com/charisfoodng/" target="blank" className="footer__social">
              <FiTwitter />
            </a>
          </div>
        </div>
      </div>
      {/* <div className="footer__middle">View Mobile Site</div> */}
      <div className="footer__bottom">
        Charis Foods 2022 &copy;, All rights reserved. Designed by -  <a href="https://www.touchcore.com.ng" target="_blank">Touchcore </a>
      </div>
    </div>
  );
}

export default Footer;
