import React from "react";
import "./OatsCerealMix.css";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import { useRef } from "react";

function OatsCerealMix() {
  const oatRef = useRef();

  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
  return (
    <div className="oats">
      <div className="oats__headerContent">
        <div className="headerMobileImageContainer">
          <img
            src={require("../../assets/Oats1.png")}
            alt=""
            className="oats__headerImage oats__headerImageMobile"
            data-aos="fade-left"
          />
        </div>
        <div className="oats__headerContentCircle"></div>
        <div className="oats__headerContentLeft">
          <h1 className="oats__headerContentName" data-aos="fade-up">
            Oats Cereal Mix
          </h1>
          <p className="oats__headerContentText" data-aos="fade-up">
            Charis foods premium dates fruit syrup is 100% natural and extracted
            from premium quality dates.
          </p>
          <a href="#oatsContent" style={{ textDecoration: "none", cursor: "pointer" }} >
            <div className="oats__headerButton" data-aos="fade-up">
              <div className="oats__headerIcon"></div>
              <span className="oats__headerButtonText">Learn more</span>
            </div>
          </a>
        </div>
        <img
          src={require("../../assets/Oats1.png")}
          alt=""
          className="oats__headerImage"
          data-aos="fade-left"
        />
      </div>
      <div className="oats__content" id="oatsContent">
        <div className="oats__contentImage">
          <img
            src={require("../../assets/Oats2.png")}
            alt=""
            className="oats__contentImage"
          />
        </div>
        <div className="oats__contentContent" >
          <h2 className="oats__contentContentTitle" data-aos="fade-up">
            About Oats Cereal Mix
          </h2>
          <p className="oats__contentContentText" data-aos="fade-up">
            {`All Natural breakfast cereal. A blend of oats, sesame, coconut flakes, cashew nuts, almond nut, flaxseed meal and raisins.`}
          </p>
          <h2 className="oats__contentContentTitle" data-aos="fade-up">
            Key Benefits
          </h2>
          <p className="oats__contentContentText" data-aos="fade-up">
            100% natural <br />
            Zero sugar <br />
            Zero high fructose corn syrup <br />
            Zero preservatives
          </p>
          <h2 className="oats__contentContentTitle" data-aos="fade-up">
            Directions for use
          </h2>
          <p className="oats__contentContentText" data-aos="fade-up">
            As a breakfast cereal <br /> Nibble as a snack <br />Topping for yoghurt
            and ice cream
          </p>

          <h2 className="oats__contentContentTitle" data-aos="fade-up">
            Key Ingredients
          </h2>
          <p className="oats__contentContentText" data-aos="fade-up">
            Oats, nuts , seeds and dried fruits.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OatsCerealMix;
