import React from "react";
import "./Dates.css";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

function Dates() {
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
  return (
    <div className="date">
      <div className="date__headerContent">
        <div className="headerMobileImageContainer">
          <img
            src={require("../../assets/DatesSeed.png")}
            alt=""
            className="date__headerImage date__headerImageMobile"
            data-aos="fade-left"
          />
        </div>
        <div className="date__headerContentCircle"></div>
        <div className="date__headerContentLeft">
          <h1 className="date__headerContentName" data-aos="fade-up">
            Dates Seed Tea
          </h1>
          <p className="date__headerContentText" data-aos="fade-up">
            Dates Seed Tea is made from 100% dates and wonder spices such as turmeric, cinnamon and black seed.
          </p>
          <a
            href="#dateContent"
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <div className="date__headerButton" data-aos="fade-up">
              <div className="date__headerIcon"></div>
              <span className="date__headerButtonText">Learn more</span>
            </div>
          </a>
        </div>
        <img
          src={require("../../assets/DatesSeed.png")}
          alt=""
          className="date__headerImage"
          data-aos="fade-left"
        />
      </div>
      <div className="date__content" id="dateContent">
        <div className="date__contentImage">
          <img
            src={require("../../assets/DatesSeed.png")}
            alt=""
            className="date__contentImage"
          />
        </div>
        <div className="date__contentContent">
          <h2 className="date__contentContentTitle" data-aos="fade-up">
            About Date Seed Tea
          </h2>
          <p className="date__contentContentText" data-aos="fade-up">
            {`Dates Seed Tea is made from 100% dates and wonder spices such as turmeric, cinnamon and black seed. Date seeds are rich in antioxidants.`}
          </p>
          {/* <h2 className="date__contentContentTitle" data-aos="fade-up">
            Key Benefits
          </h2>
          <p className="date__contentContentText" data-aos="fade-up">
            Useful in sugar control <br />
            Prevent DNA damage <br />
            {`Date seeds are rich in antioxidants that cause prevent damage to the body’s DNA structure. Further, date seeds are effective in boosting the immune system and nerves because they have numerous minerals.`}{" "}
            <br />
            Prevent kidney stones and liver damage. Dates seeds help treatment
            for kidney stones and bladder diseases. Date kernels contain several
            medicinal compounds, one of which acts like corticosteroids and is
            used to treat kidney and bladder disorders, inflammation, and
            infectious diseases. <br />
            Antioxidants
          </p> */}
          <h2 className="date__contentContentTitle" data-aos="fade-up">
            Directions for use
          </h2>
          <p className="date__contentContentText" data-aos="fade-up">
            Use as directed on the label.
          </p>

          <h2 className="date__contentContentTitle" data-aos="fade-up">
            Key Ingredients
          </h2>
          <p className="date__contentContentText" data-aos="fade-up">
            dates seed, turmeric, black seed, cinnamon
          </p>
        </div>
      </div>
    </div>
  );
}

export default Dates;
