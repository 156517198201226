import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import OatsCerealMix from "../components/OatsCerealMix/OatsCerealMix";
import ProductContent from "../components/ProductContent/ProductContent";
import ProductsScroller from "../components/ProductsScroller/ProductsScroller";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from "../components/Contact/Contact";

function OatsPage() {
  useEffect(() => {
    // AOS.init();
    // AOS.refresh();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <OatsCerealMix />
      {/* <ProductContent /> */}
      <ProductsScroller headerText="You may also like" page="oats" />
      <Contact />
      <Footer />
    </div>
  );
}

export default OatsPage;
