import React from "react";
import "./AboutUs.css";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

function AboutUs() {
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
  return (
    <div className="aboutUs">
      <div className="aboutUs__imageContainer">
        <img
          src={require("../../assets/AboutUs.png")}
          alt=""
          className="aboutUs__image"
        />
        <div className="aboutUs__headerImages">
          <img
            src={require("../../assets/Syrup1.png")}
            alt=""
            className="aboutUs__headerImage"
          />
          <img
            src={require("../../assets/Oats1.png")}
            alt=""
            className="aboutUs__headerImage"
          />
        </div>
      </div>
      
      <div className="aboutUs__content" style={{ width:"100%" }} >
        <div className="aboutUS__contentImageContainer">
          <img
            src={require("../../assets/LadyWithABaby.png")}
            alt=""
            className="aboutUs__contentImage"
            data-aos="fade-up"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          />
        </div>
        <div className="aboutUs__contentContainer">
          <div className="aboutUs__contentTitle2" data-aos="fade-right">
            Who we are
          </div>

          <div className="aboutUs__contentContent" data-aos="fade-right">
            We are the go-to brand for functional and wholesome foods for all family members.
          </div>
          <div className="aboutUs__contentTitle" data-aos="fade-right">
            Culture and Values
          </div>
          <div className="aboutUs__contentContent" data-aos="fade-right">
            <li><b>What makes us so unique.</b></li>
            We are passionate about the health and well-being of our customers. We are bold in our innovative approach to food product development and processing.
            We act safely and ethically and continuously pursue excellence, effectiveness and efficiency.
          </div>
          <div className="aboutUs__contentTitle" data-aos="fade-right">
            HSE principles
          </div>
          <div className="aboutUs__contentContent" data-aos="fade-right">
            <li><b>No one gets hurt</b></li>
          
            Food safety and quality are our number one priority. It's our foundational pillar as we are dedicated to the health and well-being of our customers.
          </div>

          <div className="aboutUs__contentTitle" data-aos="fade-right">
            Vision
          </div>
          <div className="aboutUs__contentContent" data-aos="fade-right">
            To be the leading brand in functional and wholesome foods globally.
          </div>

          <div
            className="aboutUs__contentTitle" data-aos="fade-right"
          >
            Mission
          </div>
          <div className="aboutUs__contentContent" data-aos="fade-right">
            To produce functional and wholesome foods using locally sourced raw materials.
          </div>

          <div
            className="aboutUs__contentTitle" data-aos="fade-right"
          >
            Our Strategy
          </div>
          <div className="aboutUs__contentContent" data-aos="fade-right">
            Our portfolio is ever increasing to meet the nutrition demands of
            our consumers, food security and food diversification.
          </div>

          <div
            className="aboutUs__contentTitle" data-aos="fade-right"
          >
            How we do business
          </div>
          <div className="aboutUs__contentContent" data-aos="fade-right">
            {/* <li>Our code of conduct</li> */}
            <li>
              Compliance to all food standards , policies and laws. As well as
              Good manufacturing practice.
            </li>
            <li>Commitment to employee safety and growth.</li>
            <li>Commitment to innovation, honesty and integrity.</li>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
