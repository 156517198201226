import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import WhereTo from "../components/WhereTo/WhereTo";

function WhereToBuy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <WhereTo />
      <Footer />
    </div>
  );
}

export default WhereToBuy;
