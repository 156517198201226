import React, { useEffect } from "react";
import AboutUs from "../components/AboutUs/AboutUs";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";
import ProductsScroller from "../components/ProductsScroller/ProductsScroller";
import AOS from "aos";
import "aos/dist/aos.css";

function AboutUsPage() {
  useEffect(() => {
    // AOS.init();
    // AOS.refresh();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <AboutUs />
      <ProductsScroller headerText="Learn more about our products" />
      <Contact />
      <Footer />
    </div>
  );
}

export default AboutUsPage;
