import React from 'react'
import WhyCard from '../WhyCard/WhyCard'
import "./Why.css"
import Natural from "../../assets/Natural.svg"
import Healthy from "../../assets/Healthy.svg"
import NoSugar from "../../assets/NoSugar.svg"
import Contact from '../Contact/Contact'

function Why() {
  return (
    <div className='why'>
        <span className="why__header">Why people choose us?</span>
        <div className="whyCards">
            <WhyCard description="100% Natural" image={Natural} width={60} />
            <WhyCard description="100% Healthy" image={Healthy} width={60} />
            <WhyCard description="100% No Added Sugar" image={NoSugar} height={60} />
        </div>
        <div className='why__contact'>
            <Contact />
        </div>
    </div>
  )
}

export default Why