import React, { useEffect } from "react";
import ContactUs from "../components/ContactUs/ContactUs";
import Footer from "../components/Footer/Footer";
import AOS from "aos";
import "aos/dist/aos.css";

function ContactUsPage() {
  useEffect(() => {
    // AOS.init();
    // AOS.refresh();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <ContactUs />
      <Footer />
    </div>
  );
}

export default ContactUsPage;
