import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import AboutUsPage from "./screens/AboutUsPage";
import HomePage from "./screens/HomePage";
import ContactUsPage from "./screens/ContactUsPage";
import OatsPage from "./screens/OatsPage";
import MultiGrainPage from "./screens/MultiGrainPage";
import WholeMealPage from "./screens/WholeMealPage";
import DatesPage from "./screens/DatesPage";
import SyrupPage from "./screens/SyrupPage";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import WhereToBuy from "./screens/WhereToBuy";

function App() {
  // useEffect(() => {
  //   AOS.init();
  //   AOS.refresh();
  // }, []);

  return (
    <Router>
      <div className="app">
        <div className="appHeader">
          <Header />
        </div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/where" element={<WhereToBuy />} />
          <Route path="/product/oats" element={<OatsPage />} />
          <Route path="/product/grain" element={<MultiGrainPage />} />
          <Route path="/product/syrup" element={<SyrupPage />} />
          <Route path="/product/meal" element={<WholeMealPage />} />
          <Route path="/product/date" element={<DatesPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
