import React from "react";
import Distributor from "../Distributor/Distributor";
import "./WhereTo.css";

function WhereTo() {
  return (
    <div className="whereTo">
      <div className="whereTo__header">
        <span className="whereTo__headerName">Where To Buy</span>
        <span className="whereTo__subHeaderName">
          {/* Get in touch with our distributors */}
        </span>
        <div className="whereTo__images">
          <img
            src={require("../../assets/Where1.png")}
            alt=""
            className="whereTo__image1"
          />
          <img
            src={require("../../assets/Where2.png")}
            alt=""
            className="whereTo__image2"
          />
          <img
            src={require("../../assets/Where3.png")}
            alt=""
            className="whereTo__image3"
          />
        </div>
      </div>
      <div className="whereTo__distributors">
        <Distributor
          name="Next Cash and Carry Supermarket"
          description="Nigeria's finest hypermart"
          location="Behind Pabod Brewery, Oginigba, Trans Amadi, Port Harcourt"
          phone="0705 784 8364"
        />
        <Distributor
          name="Nextime Supermarket"
          description="General stores"
          location="Lenu Plaza Sani Abacha Road, GRA Phase 111, Rumueme, Port Harcourt"
          phone="0702 500 2009"
          
        />
        <Distributor
          name="Livinchun supermarket "
          description="General store"
          location="opp. Oando, 181 Peter Odili Rd, Trans Amadi 500272, Port Harcourt"
          
         
        />
        <Distributor
          name="Chanrais Supermarket"
          description="A little description of the distributor"
          location="13 Port Harcourt - Aba Expy, New GRA 500101, Port Harcourt"
          phone="0816 771 7359"
          email="distributor@charisfood.com"
        />
        <Distributor
          name="Kitchen World"
          description="Grocery Store"
          location="1 Damiete Dr, Abuloma 500101, Port Harcourt"
          phone=" 0902 600 0840"
          
        />
        <Distributor
          name="Afor Market PHC"
          description="Food products supplier"
          location="1, Lydia Abam close, Peter Odili Rd, Port Harcourt"
          phone="0818 852 4117"
          
        />
        <Distributor
          name="Wide Choice Supermarket"
          description="General store"
          location="Plot 170, Ada-George Road, Rumuochita 500272, Port Harcourt"
          phone="0806 917 5834"

        />
        <Distributor
          name="Wide Choice Supermarket"
          description="General store"
          location="RXF5+FVR, Rumuepirikom 500102, Port Harcourt"
          phone="0806 917 5834"

        />
        <Distributor
          name="Nengi Bio Super Market & Services Ltd"
          description="Grocery store"
          location="7 Rumukalagbor road off elekahia waterlines, Link Road, Port Harcourt"
          phone="0806 793 6230"

        />
        <Distributor
          name="Wincare Supermarket"
          description="General store"
          location="Plot 2B, 4th Street in Elekahia Housing Estate, Port Harcourt"
          phone="0803 668 7187"

        />
        <Distributor
          name="Maryland supermarket "
          description="Grocery store"
          location="V2R6+2PJ, Eneka Link Rd, 500102, Port Harcourt"
          phone="-"

        />
      </div>
    </div>
  );
}

export default WhereTo;
