import React from "react";
import "./MultiGrainCereal.css";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

function MultiGrainCereal() {
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
  return (
    <div className="grain">
      <div className="grain__headerContent">
        <div className="headerMobileImageContainer">
          <img
            src={require("../../assets/MultiGrain1.png")}
            alt=""
            className="grain__headerImage grain__headerImageMobile"
            data-aos="fade-left"
          />
        </div>
        <div className="grain__headerContentCircle"></div>
        <div className="grain__headerContentLeft">
          <h1 className="grain__headerContentName" data-aos="fade-up">
            Multi Grain Cereal
          </h1>
          <p className="grain__headerContentText" data-aos="fade-up">
            Charis foods premium dates fruit syrup is 100% natural and extracted
            from premium quality dates.
          </p>
          <a href="#grainContent" style={{ textDecoration: "none", cursor: "pointer" }}>
            <div className="grain__headerButton" data-aos="fade-up">
              <div className="grain__headerIcon"></div>
              <span className="grain__headerButtonText">Learn more</span>
            </div>
          </a>
        </div>
        <img
          src={require("../../assets/MultiGrain1.png")}
          alt=""
          className="grain__headerImage"
          data-aos="fade-left"
        />
      </div>
      <div className="grain__content" id="grainContent">
        <div className="grain__contentImage">
          <img
            src={require("../../assets/MultiGrain2.png")}
            alt=""
            className="grain__contentImage"
          />
        </div>
        <div className="grain__contentContent">
          <h2 className="grain__contentContentTitle" data-aos="fade-up">
            About Multi Grain Cereal
          </h2>
          <p className="grain__contentContentText" data-aos="fade-up">
            {`Multigrain is a cereal that gives value.
It is a natural healthy cereal for Children from six months to adulthood and a sugar-free energy booster meal.  

It contains a blend of millet, sorghum, maize, green banana, soybean, almonds nuts, and groundnuts.

Multigrain cereal is rich in fibre and consists of protein, iron, magnesium, potassium, carbohydrate, vitamin B6 and Vitamin B12, among others.`}
          </p>
          <h2 className="grain__contentContentTitle" data-aos="fade-up">
            Key Benefits
          </h2>
          <p className="grain__contentContentText" data-aos="fade-up">
            100% natural <br />
            Zero sugar <br />
            Zero preservatives <br />
          </p>
          <h2 className="grain__contentContentTitle" data-aos="fade-up">
            Directions for user
          </h2>
          <p className="grain__contentContentText" data-aos="fade-up">
            Use as directed on the label.
          </p>

          <h2 className="grain__contentContentTitle" data-aos="fade-up">
            Key Ingredients
          </h2>
          <p className="grain__contentContentText" data-aos="fade-up">
            Guinea corn, Millet, Soybeans, Craysfish, Groundnut, Almond nuts and
            Green banana
          </p>
        </div>
      </div>
    </div>
  );
}

export default MultiGrainCereal;
