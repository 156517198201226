import React from "react";
import "./Distributor.css";
import Logo from "../../assets/CharisLogo.svg";
import { TiLocation } from "react-icons/ti";
import { BsFillTelephoneFill } from "react-icons/bs";


function Distributor({ name, description, location, phone, email }) {
  return (
    <div className="distributor">
      <img src={Logo} alt="" className="distributor__logo" />
      <div className="distributor__content">
        <span className="distributor__name">{name}</span>
        <span className="distributor__description">{description}</span>
        <div className="distributor__info">
          <div
            className="ditributor__infoIconContainer"
            style={{ fontSize: 18 }}
          >
            <TiLocation />
          </div>
          <span className="distributor__infoName">{location}</span>
        </div>
        <div className="distributor__info">
          <div className="ditributor__infoIconContainer">
            <BsFillTelephoneFill />
          </div>
          <span className="distributor__infoName">{phone}</span>
        </div>
        
      </div>
    </div>
  );
}

export default Distributor;
