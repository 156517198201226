import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import ProductContent from "../components/ProductContent/ProductContent";
import ProductsScroller from "../components/ProductsScroller/ProductsScroller";
import Syrup from "../components/Syrup/Syrup";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from "../components/Contact/Contact";

function SyrupPage() {
  useEffect(() => {
    // AOS.init();
    // AOS.refresh();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <Syrup />
      {/* <ProductContent /> */}
      <ProductsScroller headerText="You may also like" page="syrup" />
      <Contact />
      <Footer />
    </div>
  );
}

export default SyrupPage;
