import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "../Card/Card";
import "./Products.css";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

function Products() {

  const navigate = useNavigate()
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);

  return (
    <div className="products">
      <div className="products__header" data-aos="fade-right">
        Enjoy healthy and delicious food from Charis
      </div>
      <div className="products__products" data-aos="fade-right">
        Our Products
      </div>
      <div className="products__info" data-aos="fade-right">
        100% Natural and heathy foods with no added sugar
      </div>
      
      <div className="cards">
        <Card
          image={require("../../assets/Syrup1.png")}
          width={170}
          height={170}
          marginTop={20}
          marginLeft={30}
          name="Premium Date Fruit Syrup"
          description="100% Natural"
          onClick={()=>navigate("/product/syrup")}
        />
        <Card
          image={require("../../assets/MultiGrain3.png")}
          width={170}
          height={150}
          marginTop={30}
          marginLeft={130}
          name="Multi Grain Cereal"
          description="100% Whole Grains"
          onClick={()=>navigate("/product/grain")}
        />
        <Card
          image={require("../../assets/WholeMeal3.png")}
          width={170}
          height={150}
          marginTop={30}
          marginLeft={130}
          name="Multi-grain Whole Meal"
          description="100% Whole Meal"
          onClick={()=>navigate("/product/meal")}
        />
        <Card
          image={require("../../assets/Oats3.png")}
          width={170}
          height={170}
          marginTop={20}
          marginLeft={130}
          name="Oats Cereal Mix"
          description="100% Natural"
          onClick={()=>navigate("/product/oats")}
        />
        <Card
          image={require("../../assets/DatesSeed.png")}
          width={200}
          height={200}
          marginTop={20}
          marginLeft={130}
          name="Dates Seed tea"
          description="100% Date Seed"
          onClick={()=>navigate("/product/date")}
        />
    
        <div style={{ width:360, marginLeft:20, marginRight:20 }}></div>
    
      </div>
    </div>
  );
}

export default Products;
