import React from "react";
import "./Syrup.css";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

function Syrup() {
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);

  return (
    <div className="syrup">
      <div className="syrup__headerContent">
        <div className="headerMobileImageContainer">
          <img
            src={require("../../assets/Syrup1.png")}
            alt=""
            className="syrup__headerImage syrup__headerImageMobile"
            data-aos="fade-left"
          />
        </div>
        <div className="syrup__headerContentCircle"></div>
        <div className="syrup__headerContentLeft">
          <h1 className="syrup__headerContentName" data-aos="fade-up">
            Premium Date Fruit Syrup
          </h1>
          <p className="syrup__headerContentText" data-aos="fade-up">
            Charis foods premium dates fruit syrup is 100% natural and extracted
            from premium quality dates.
          </p>
          <a
            href="#syrupContent"
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <div className="syrup__headerButton" data-aos="fade-up">
              <div className="syrup__headerIcon"></div>
              <span className="syrup__headerButtonText">Learn more</span>
            </div>
          </a>
        </div>
        <img
          src={require("../../assets/Syrup1.png")}
          className="syrup__headerImage"
          data-aos="fade-left"
        />
      </div>
      <div className="syrup__content" id="syrupContent">
        <div className="syrup__contentImage">
          <img
            src={require("../../assets/Syrup2.png")}
            alt=""
            className="syrup__contentImage"
          />
        </div>
        <div className="syrup__contentContent">
          <h2 className="syrup__contentContentTitle" data-aos="fade-up">
            About Premium Date Fruit Syrup
          </h2>
          <p className="syrup__contentContentText" data-aos="fade-up">
            {`Premium dates fruit syrup is 100% natural, extracted from premium quality dates, a low glycemic sweetener and a good source of calcium and iron. It also strengthens the immune system and is a rich source of potassium. It's the World's most nutrient-dense sweetener, and it is an ideal sweetener for babies.`}
          </p>
          <h2 className="syrup__contentContentTitle" data-aos="fade-up">
            Key Benefits
          </h2>
          <p className="syrup__contentContentText" data-aos="fade-up">
            100% natural. <br /> No added sugar, and preservatives. <br />{" "}
            Source of calcium and iron. 
          </p>
          <h2 className="syrup__contentContentTitle" data-aos="fade-up">
            Directions for use
          </h2>
          <p className="syrup__contentContentText" data-aos="fade-up">
            
            Use as directed on the label.<br />Sugar replacement for tea and cereals.<br />Excellent topping for yoghurt and parfait.
          </p>
          <h2 className="syrup__contentContentTitle" data-aos="fade-up">
            Safety Information
          </h2>
          <p className="syrup__contentContentText" data-aos="fade-up">
            Do not consume if the bottle is swollen
          </p>
          <h2 className="syrup__contentContentTitle" data-aos="fade-up">
            Key Ingredients
          </h2>
          <p className="syrup__contentContentText">Dates</p>
        </div>
      </div>
    </div>
  );
}

export default Syrup;
