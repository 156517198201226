import React, { useState } from "react";
import "./Home.css";
import Right from "../../assets/arrowRight.svg";
import Left from "../../assets/arrowLeft.svg";
import { useEffect } from "react";

function Header1() {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div className="home__headerImages">
        <img
          src={require("../../assets/Syrup1.png")}
          alt=""
          className="home__headerImage"
        />
        <img
          src={require("../../assets/Oats1.png")}
          alt=""
          className="home__headerImage"
        />
      </div>
      <img
        src={require("../../assets/HomeImage1.png")}
        alt=""
        className="home__image"
      />
    </div>
  );
}

function Header2() {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div className="home__headerImages home__headerImages2">
        <img
          src={require("../../assets/Syrup1.png")}
          alt=""
          className="home__headerImage home__headerImage2"
        />
        <img
          src={require("../../assets/Oats1.png")}
          alt=""
          className="home__headerImage home__headerImage1"
        />
      </div>
      <img
        src={require("../../assets/HomeImage2.png")}
        alt=""
        className="home__image"
      />
    </div>
  );
}

function Home() {
  const [active, setActive] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (active < 3) {
        setActive(active + 1);
        return;
      }
      if (active >= 3) {
        setActive(1);
        return;
      }
    }, 5000);
    return () => clearInterval(intervalId);
  }, [active]);

  return (
    <div className="home">
      <div className="arrows">
        <div className="arrowsContainer">
          {active > 1 ? (
            <img
              src={Left}
              alt=""
              className="left__arrow"
              onClick={() => {
                if (active === 1) {
                  setActive(3);
                }
                if (active === 2) {
                  setActive(1);
                }
                if (active === 3) {
                  setActive(2);
                }
              }}
            />
          ) : (
            <div></div>
          )}
          {active < 3 && (
            <img
              src={Right}
              alt=""
              className="right__arrow"
              onClick={() => {
                if (active === 1) {
                  setActive(2);
                }
                if (active === 2) {
                  setActive(3);
                }
                if (active === 3) {
                  setActive(1);
                }
              }}
            />
          )}
        </div>
      </div>
      <div className="home__imageContainer">
        <div className={active === 1 ? "slide active" : "slide"}>
          <Header1 />
        </div>
        <div className={active === 2 ? "slide active" : "slide"}>
          <Header2 />
        </div>
        <div className={active === 3 ? "slide active" : "slide"}>
          <div className="header3">
            <div className="header3__headerContent">
              <div className="header3__headerContentCircle"></div>
              <div className="header3__headerContentLeft">
                <h1 className="header3__headerContentName">
                  Functional and wholesome foods
                </h1>
                <p className="header3__headerContentText">
                  100% Natural and heathy foods with no added sugar
                </p>
                <a href="#products" style={{ textDecoration: "none" }}>
                  <div className="header3__headerButton">
                    <div className="header3__headerIcon"></div>
                    <span className="header3__headerButtonText">
                      View our products
                    </span>
                  </div>
                </a>
              </div>
              <img
                src={require("../../assets/LadyWithABaby.png")}
                alt=""
                className="header3__headerImage"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="home__mobile">
        <img
          src={require("../../assets/LadyWithABaby.png")}
          alt=""
          className="home__mobileImage"
        />
        <div className="home__mobileHeaderText">
          Enjoy functional and wholesome foods
        </div>
        <a href="#products" style={{ textDecoration: "none" }}>
          <div className="header3__headerButton">
            <div className="header3__headerIcon"></div>
            <span className="header3__headerButtonText">View our products</span>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Home;
