import React from "react";
import "./About.css";
import { useNavigate } from "react-router-dom";
import Bag from "../../assets/Bag.svg";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

function About() {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);

  return (
    <div className="about">
      <div className="about__section" data-aos="fade-up-left">
        <div className="about__header">
          <span className="about__headerLearn">Learn more</span>
          <span className="about__headerText">About Us</span>
        </div>
        <p className="about__content" >
          Charis foods is a registered trade mark of Pisonville resources LTD.
          We are a Nigerian brand with a passion to create and bring to market
          functional and wholesome foods using locally sourced raw materials.
        </p>
        <div className="about__button" onClick={() => navigate("/about")}>
          <div className="about__buttonIconContainer">
            <img src={Bag} alt="" className="about__bagIcon" />
          </div>
          <span className="about__buttonText">Learn More</span>
        </div>
      </div>
      <img
        src={require("../../assets/About.png")}
        alt=""
        className="about__image"
      />
    </div>
  );
}

export default About;
