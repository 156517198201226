import React, { useEffect } from "react";
import About from "../components/About/About";
import Footer from "../components/Footer/Footer";
import Home from "../components/Home/Home";
import Products from "../components/Products/Products";
import Why from "../components/Why/Why";
import AOS from "aos";
import "aos/dist/aos.css";

function HomePage() {
  useEffect(() => {
    // AOS.init();
    // AOS.refresh();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      <Home />
      <div id="products">
        <Products />
      </div>
      <About />
      <Why />
      <Footer />
    </div>
  );
}

export default HomePage;
