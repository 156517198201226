import React from "react";
import "./ContactUs.css";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail, MdLocationOn } from "react-icons/md";
import { useState } from "react";

function ContactUs() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  return (
    <div className="contactUs">
      <span className="contactUs__header">Contact Us</span>
      <span className="contactUs__subHeader">Get in touch with us</span>
      <div className="contactUs__items">
        <div className="contactUs__item">
          <div className="contactUs__iconContainer">
            <div className="contactUs__iconBackground"></div>
            <BsTelephoneFill />
          </div>
          <span className="contactUs__itemName">Phone</span>
          <div className="contactUs__content">
            <span className="contactUs__itemContentText">
              Give us a call on anything
            </span>
            <span className="contactUs__itemContentText contactUs__itemContentText2">
              +234 703 873 6024
            </span>
          </div>
        </div>
        <div className="contactUs__item">
          <div className="contactUs__iconContainer">
            <div className="contactUs__iconBackground"></div>
            <MdEmail />
          </div>
          <span className="contactUs__itemName">Email</span>
          <div className="contactUs__content">
            <span className="contactUs__itemContentText">
              We usually respond within 24 hours on working days
            </span>
            <span className="contactUs__itemContentText contactUs__itemContentText2">
              info@charisfoodsltd.com
            </span>
          </div>
        </div>
        <div className="contactUs__item">
          <div className="contactUs__iconContainer">
            <div className="contactUs__iconBackground"></div>
            <MdLocationOn />
          </div>
          <span className="contactUs__itemName">Location</span>
          <div className="contactUs__content">
            <span className="contactUs__itemContentText">
              Rumuodara, Port Harcourt, Rivers State - Nigeria
            </span>
          </div>
        </div>
      </div>
      <div className="contact__form">
        <span className="contact__formHeader">Leave Us Your Info</span>
        <span className="contact__formSubHeader">
          and we will get back to you.
        </span>
        <form action="contact" className="contact__formForm">
          <div className="contact__formRow1">
            <div className="contact__formRowColumn">
              <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Full Name"
              />
            </div>
            <div className="contact__formRowColumn">
              <input type="text" placeholder="Email" />
            </div>
          </div>
          <div className="contact__formRow2">
            <div className="contact__formRowColumn">
              <input
                type="text"
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
          </div>
          <div className="contact__formRow3">
            <div className="contact__formRowColumn">
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
          </div>
          <button
            onClick={() => console.log(fullName)}
            className="contact__formButton"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
